<template>
  <div class="loading-indicator">
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
  </div>
</template>

<style lang="scss" scoped>
$dot-size: 10.4px;

.loading-indicator {
  height: $dot-size * 2;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: calc($dot-size / 1.5);
}

.dot {
  width: $dot-size;
  height: $dot-size;
  border-radius: 50%;
  transform: translateY(50%);
  animation-name: jump;
  animation-duration: 0.6s;
  animation-direction: alternate;
  animation-iteration-count: infinite;

  &:nth-child(2) {
    animation-delay: 0.2s;
  }
  &:nth-child(3) {
    animation-delay: 0.4s;
  }

  background-color: var(--primary-light);
}

@keyframes jump {
  100% {
    background-color: var(--primary);
    transform: translateY(-50%);
  }
}
@keyframes jump-light {
  100% {
    background-color: var(--primary-light);
    transform: translateY(-50%);
  }
}
</style>
